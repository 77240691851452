import { AspectRatio, Box, Button, Flex, VisuallyHidden } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { IconComp } from 'components/base/IconComp';
import { fadeUp, spin } from 'helpers/animation';
import { distinctLangaugeVariants } from 'lib/locales/languages';
import Image from 'next/image';
import { normalize } from 'pages/api/sitemap';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { withTranslations } from 'store/translations';
import { moduleMaxWidth } from 'themes/overrides';
import { internalPages } from 'utils/getInternalPage';
import { imageFormatter } from 'utils/imageFormatter';

import CursorLeft from '../../../public/images/cursor-arrow-left.png';
import CursorRight from '../../../public/images/cursor-arrow-right.png';
import {
  ContentPage,
  ContentPageActor,
  FrontpageCarouselHero as FrontpageCarouselHeroModel,
} from '../../models/content-types';

const IMAGE_CHANGE_INTERVAL = 5000;

interface FrontpageCarouselHeroProps {
  model: FrontpageCarouselHeroModel;
}

export const FrontpageCarouselHero: FunctionComponent<FrontpageCarouselHeroProps> = ({ model }) => {
  const translations = withTranslations();
  const [_cookie, setCookie] = useCookies(['NEXT_LOCALE']);
  const locale = translations.locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE! ? '' : `/${translations.locale}`;

  const ammountOfPages = model.elements.showcasedContent.linkedItems.length;

  const images = model.elements.showcasedContent.linkedItems.map((item) =>
    imageFormatter(
      item.elements.summaryImageFrontify.value,
      item.elements.summaryAlternativeText.value,
      false,
      item.elements.summaryCompressImage.value ? item.elements.summaryCompressImage.value : 75,
    ),
  );
  const items = model.elements.showcasedContent.linkedItems;

  const [currentImgIndex, setCurrentImgIndex] = useState(0);
  const [isAnimationActive, setIsAnimationActive] = useState(true);
  const [cursorPosition, setCursorPosition] = useState<string>();
  const [cursor, setCursor] = useState<string>();

  const setImgIndex = () => {
    setCurrentImgIndex((prevIndex) => {
      if (prevIndex + 1 > ammountOfPages - 1) {
        return 0;
      }
      return prevIndex + 1;
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isAnimationActive) {
        clearInterval(interval);
      } else {
        setImgIndex();
      }
    }, IMAGE_CHANGE_INTERVAL);
    return () => clearInterval(interval);
  }, [isAnimationActive]);

  const checkCursor = (onClickCursorPosition?: string) => {
    const currentCursorPosition = onClickCursorPosition ?? cursorPosition;
    if (currentCursorPosition === 'left' && currentImgIndex > 0) {
      setCursor('left');
      return 'left';
    }
    if (currentCursorPosition === 'left' && currentImgIndex === 0) {
      setCursor('right');
      return 'right';
    }
    if (currentCursorPosition === 'right' && currentImgIndex < ammountOfPages - 1) {
      setCursor('right');
      return 'right';
    }
    if (currentCursorPosition === 'right' && currentImgIndex === ammountOfPages - 1) {
      setCursor('left');
      return 'left';
    }
  };

  useEffect(() => {
    checkCursor();
  }, [currentImgIndex]);

  const getCarouselImage = () => {
    return (
      <Box
        id='imageSlideshow'
        display='grid'
        gridTemplateColumns={`repeat(2, 100%)`}
        gridTemplateRows={`repeat(1, 100%)`}
        w='100%'
        h='100%'
        position={{ base: 'absolute', lg: 'relative' }}
        top='0'
        zIndex={-1}
        overflow='hidden'
      >
        {images.map((media, index) => {
          const image = model.elements.showcasedContent.linkedItems[index];
          const focalpointLeftvalue = image.elements.summaryLeftFocalPoint.value
            ? `${image.elements.summaryLeftFocalPoint.value}% `
            : '50%';
          const focalpointRightvalue = image.elements.summaryRightFocalPoint.value
            ? `${image.elements.summaryRightFocalPoint.value}% `
            : '50%';
          const compressimage = image.elements.summaryCompressImage.value
            ? image.elements.summaryCompressImage.value
            : 75;

          const focalpointvalue = focalpointLeftvalue + focalpointRightvalue;
          return (
            <Image
              key={items[index].system.codename}
              style={{
                alignSelf: 'center',
                transform: `${
                  currentImgIndex === index
                    ? 'translateX(0)'
                    : currentImgIndex < index
                    ? 'translateX(100%)'
                    : currentImgIndex > index
                    ? 'translateX(-100%)'
                    : 'translateX(100px)'
                }`,
                objectFit: 'cover',
                opacity: 1,
                objectPosition: focalpointvalue,
                position: 'absolute',
                right: '0px',
                transition: 'transform 1s ease-in-out',
                width: '100%',
                height: '100%',
              }}
              aria-hidden='true'
              src={media.src}
              alt={media.alt}
              width={media.width}
              height={media.height}
              quality={compressimage}
            />
          );
        })}
      </Box>
    );
  };

  const getCarouselTextContent = useMemo(() => {
    if (translations.common('learnMore') === '') {
      return;
    }
    let idx = currentImgIndex;
    if (idx >= items.length) {
      setCurrentImgIndex(0);
      idx = 0;
    }

    const item = items[idx];
    const shouldHideBody = model.elements.hideElements.value.some((element) => element.codename === 'body');
    const shouldHideCta = model.elements.hideElements.value.some((element) => element.codename === 'cta');
    const body =
      item &&
      createRichTextHtmlResolver(nodeParser).resolveRichText({
        element: item.elements.summaryDescription,
      }).html;

    let url: string | undefined;
    switch (item?.system.type) {
      case 'content_page':
        url = Object.keys(normalize(item as ContentPage, translations.locale, undefined, true, translations))[0];
        break;
      case 'content_page_actor':
        url = (item as ContentPageActor)?.elements.internalCtaLink.value[0]?.codename
          ? `${locale}${internalPages(
              translations,
              (item as ContentPageActor).elements.internalCtaLink.value[0]?.codename,
            )}`
          : (item as ContentPageActor)?.elements.externalCtaLink.value;
        break;
      default:
        url = undefined;
        break;
    }
    const isLinkExternal = url?.startsWith('http');

    return (
      <Flex
        position={{ base: 'static', lg: 'absolute' }}
        width='100%'
        h='100%'
        flexDirection='column'
        justifyContent='space-between'
      >
        {/* ------------ PAUSE BUTTON ------------  */}
        {ammountOfPages > 1 && (
          <Box
            order='3'
            position={'absolute'}
            bottom={{ base: 's', lg: 'l' }}
            right='30px'
            display={{ base: 'none', md: 'flex' }}
            cursor='auto'
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Button
              aria-controls='imageSlideshow'
              maxW='fit-content'
              p='xxs'
              gap='xxs'
              textColor={'white'}
              textStyle='desktop.body.s'
              bg='mountain.base'
              cursor='pointer'
              pointerEvents={'auto'}
              onClick={(e) => {
                if (!isAnimationActive) {
                  setImgIndex();
                }
                setIsAnimationActive(!isAnimationActive);
                e.stopPropagation();
              }}
            >
              {isAnimationActive ? (
                <IconComp iconName='pause' chakraStyle={{ path: { fill: 'white' }, w: '24px', h: '24px' }} />
              ) : (
                <IconComp iconName='play' chakraStyle={{ w: '24px', h: '24px' }} />
              )}
              {isAnimationActive ? translations.common('pause') : translations.common('play')}
              <VisuallyHidden>{translations.aria('imageCarousel')}</VisuallyHidden>
            </Button>
          </Box>
        )}
        {/* ------------ PAUSE BUTTON END ------------  */}

        {/* ------------ TEXT CONTENT ------------  */}
        <Box
          key={idx}
          mx={{ base: 'xs', lg: 'l' }}
          animation={`${fadeUp} 1s ease`}
          order='1'
          position='relative'
          top='50%'
          transform='translateY(-50%)'
        >
          <Box
            as='h2'
            maxW='1200px'
            minH={{ md: '2em' }}
            textStyle={{ base: 'mobile.h2', md: 'tablet.h1', lg: 'desktop.h1' }}
            color='white'
            pointerEvents='none'
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {item?.elements.summaryTitle.value ?? ''}
          </Box>
          {!shouldHideBody && body && (
            <Box
              mt='m'
              cursor='auto'
              textStyle={{ base: 'mobile.body.s', lg: 'desktop.body.book.l' }}
              color='white'
              maxW='540px'
              display='flex'
              flexDir='column'
              gap='xs'
              pointerEvents='none'
              dangerouslySetInnerHTML={{ __html: body }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          )}
          {!shouldHideCta && !!url && (
            <Box
              mt='30px'
              cursor='auto'
              w='fit-content'
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Button
                target={isLinkExternal ? '_blank' : ''}
                as='a'
                borderRadius='4px'
                href={url}
                cursor={'pointer'}
                pointerEvents={'auto'}
                variant='primary'
                size='medium'
                textStyle='mobile.body.s'
                fontWeight={500}
                onClick={() =>
                  !isLinkExternal &&
                  distinctLangaugeVariants.includes(url!.split('/')[1]) &&
                  setCookie('NEXT_LOCALE', url!.split('/')[1], { path: '/' })
                }
              >
                {translations.common('learnMore')}
                <VisuallyHidden>
                  {translations.aria('about')} {item.elements.summaryTitle.value}
                </VisuallyHidden>
              </Button>
            </Box>
          )}
        </Box>
        {/* ------------ TEXT CONTENT END ------------  */}

        {/* ------------ IMAGE PAGINATION ------------  */}
        {ammountOfPages > 1 && (
          <Box
            order='2'
            aria-hidden='true'
            display={'flex'}
            w='100%'
            h='auto'
            pt={{ base: 's', lg: 'm' }}
            pb={{ base: 's', lg: 'l' }}
            alignSelf='flex-end'
            justifyContent='center'
            alignItems='center'
            cursor='auto'
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {images.map((_, index) => {
              return (
                <Button
                  key={index}
                  aria-hidden='true'
                  bg='none'
                  _hover={{
                    bg: 'none',
                  }}
                  _focus={{
                    bg: 'none',
                    border: 'none',
                  }}
                  cursor='pointer'
                  px={0}
                  w='30px'
                  h='30px'
                  minW='auto'
                  onClick={(e) => {
                    setIsAnimationActive(false);
                    setCurrentImgIndex(index);
                    e.stopPropagation();
                  }}
                >
                  <Box
                    position='relative'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    cursor='pointer'
                    w='30px'
                    h='30px'
                  >
                    {/* circle back */}
                    <Box as='svg' viewBox='0 0 52 52' position='absolute' inset='0' strokeWidth='1px' fill='none'>
                      <circle cx='26' cy='26' r='25' />
                    </Box>
                    {/* circle front */}
                    <Box
                      as='svg'
                      viewBox='0 0 52 52'
                      position='absolute'
                      inset='0'
                      color='white'
                      stroke='white'
                      strokeWidth='2px'
                      fill='none'
                      transform='rotate(-90deg)'
                      strokeDasharray={index === idx ? '0 157' : '157 157'}
                      strokeDashoffset={index === idx ? '0' : '-157'}
                      transition={index === idx || !isAnimationActive ? 'none' : 'stroke-dashoffset ease-in-out 500ms'}
                      animation={index === idx && isAnimationActive ? `${spin} 5s linear` : ''}
                      sx={{
                        animationFillMode: index === idx ? 'forwards' : '',
                      }}
                    >
                      <circle cx='26' cy='26' r='25' />
                    </Box>
                    {/* dot */}
                    <Box
                      as='span'
                      display='block'
                      w='8px'
                      h='8px'
                      bg={'white'}
                      opacity={index === idx ? 1 : 0.5}
                      borderRadius='8px'
                      boxShadow={'2px 2px 7px black'}
                    />
                  </Box>
                </Button>
              );
            })}
          </Box>
        )}
        {/* ------------ IMAGE PAGINATION END ------------  */}
      </Flex>
    );
  }, [translations, isAnimationActive, currentImgIndex]);

  const DesktopCarousel = (
    <AspectRatio
      h='100vh'
      position='relative'
      display={{ base: 'none', lg: 'block' }}
      ratio={16 / 9}
      pointerEvents={ammountOfPages > 1 ? 'auto' : 'none'}
      cursor={
        ammountOfPages > 1
          ? cursor === 'left'
            ? `url('${CursorLeft.src}') 0 26, auto`
            : `url('${CursorRight.src}') 53 26, auto`
          : 'auto'
      }
      px='0'
      bg='linear-gradient(90deg, rgba(39, 57, 67, 0.3) 32.4%, rgba(39, 57, 67, 0) 100%);'
      onMouseMove={(e) => {
        const isCursorOnLeftSide = e.clientX < window.innerWidth / 2;
        setCursorPosition(isCursorOnLeftSide ? 'left' : 'right');
        checkCursor();
      }}
      onClick={(e) => {
        // there is no onMouseMove event on mobile devices, therefore we need to calculate cursor position in
        // in onClick event as well
        const isCursorOnLeftSide = e.clientX < window.innerWidth / 2;
        setCursorPosition(isCursorOnLeftSide ? 'left' : 'right');
        const currentCursor = checkCursor(isCursorOnLeftSide ? 'left' : 'right');

        setIsAnimationActive(false);
        if (currentCursor === 'left' && items.length > 1) {
          setCurrentImgIndex(currentImgIndex - 1);
        } else if (currentCursor === 'right' && items.length > 1) {
          setCurrentImgIndex(currentImgIndex + 1);
        }
      }}
    >
      <Box>
        {getCarouselTextContent}
        {getCarouselImage()}
      </Box>
    </AspectRatio>
  );
  const MobileCarousel = (
    <Box
      display={{ base: 'block', lg: 'none' }}
      bg='linear-gradient(90deg, rgba(39, 57, 67, 0.3) 32.4%, rgba(39, 57, 67, 0) 100%);'
    >
      <Box h={'100vh'} position={'relative'} display='flex' alignItems={'end'}>
        {getCarouselTextContent}
        {getCarouselImage()}
      </Box>
    </Box>
  );
  return (
    <Box w='100vw' maxW={moduleMaxWidth} overflow='hidden' id='fronpage-hero-carousel'>
      {DesktopCarousel}
      {MobileCarousel}
    </Box>
  );
};
